import { EStoreTypes } from "../consts/storeTypes";

const CONFIG = {
  //Игры
  games: [
    {
      id: "shadow-tale",
      name: "Shadow Tale",
      image: "/products/shadow_tale.png",
      logo: "/icons/shadow_tale_icon.png",
      storeLinks: [
        {
          type: EStoreTypes.GOOGLE_STORE,
          url: "https://play.google.com/store/apps/details?id=com.bg.shadow.tale",
        },
      ],
    },
    {
      id: "mvc",
      name: "Monkeys vs. Cavemen",
      image: "/products/mvc.png",
      logo: "/icons/mvc.png",
      storeLinks: [
        {
          type: EStoreTypes.GOOGLE_STORE,
          url: "https://play.google.com/store/apps/details?id=com.bg.monkeys.vs.cavemen",
        },
        {
          type: EStoreTypes.RU_STORE,
          url: "https://www.rustore.ru/catalog/app/com.bg.monkeys.vs.cavemen",
        },
        {
          type: EStoreTypes.YNDX_STORE,
          url: "https://yandex.ru/games/#app=364369",
        },
      ],
    },
    {
      id: "arrow-pathway",
      name: "Pixel Arrow Pathway",
      image: "/products/arrow_pathway.png",
      logo: "/icons/arrow_pathway.png",
      storeLinks: [
        {
          type: EStoreTypes.GOOGLE_STORE,
          url: "https://play.google.com/store/apps/details?id=com.bg.arrow.pathway",
        },
        {
          type: EStoreTypes.YNDX_STORE,
          url: "https://yandex.ru/games/#app=362270",
        },
      ],
    },
    {
      id: "stickers",
      name: "Sticker Book: Paint By Number",
      image: "/products/stickers.png",
      logo: "/icons/sticker_icon.png",
      storeLinks: [
        {
          type: EStoreTypes.GOOGLE_STORE,
          url: "https://play.google.com/store/apps/details?id=com.bg.sticker.book.puzzle",
        },
        {
          type: EStoreTypes.RU_STORE,
          url: "https://www.rustore.ru/catalog/app/com.bg.sticker.book.puzzle",
        },
        {
          type: EStoreTypes.YNDX_STORE,
          url: "https://yandex.ru/games/#app=270131",
        },
      ],
    },
    {
      id: "firebomber",
      name: "Fire Bomber",
      image: "/products/firebomber.png",
      logo: "/icons/fr.png",
      storeLinks: [
        {
          type: EStoreTypes.GOOGLE_STORE,
          url: "https://play.google.com/store/apps/details?id=com.Firebomber",
        },
        {
          type: EStoreTypes.RU_STORE,
          url: "https://www.rustore.ru/catalog/app/com.Firebomber",
        },
        // {
        //   type: EStoreTypes.YNDX_STORE,
        //   url: "https://yandex.ru/games/app/258607",
        // },
      ],
    },
    {
      id: "traitorous-number",
      name: "Traitorous Number",
      image: "/products/traitorousNumber.png",
      logo: "/icons/tn.png",
      storeLinks: [
        {
          type: EStoreTypes.GOOGLE_STORE,
          url: "https://play.google.com/store/apps/details?id=com.TraitorousNumber",
        },
      ],
    },
  ],
  // Книги
  books: [
    {
      id: "elixir",
      name: "Эликсир",
      image: "/products/book.png",
      link: "https://www.litres.ru/book/bulat-gayneev/eliksir-68833206/",
    },
  ],
};

export default CONFIG;
