import { styled } from "@mui/material";
import { SlideIcon } from "./SlideIcon";
import { Button, Slider } from "components/index";

interface ISliderProps {
  isOpen: boolean;
}

export const Icon = styled(SlideIcon)<ISliderProps>`
  & > .arrow {
    transform: rotate(${({ isOpen }) => (isOpen ? "180deg" : "0")});
    transform-origin: center;
    transition: transform 0.3s ease-out;
  }
`;
export const SlideButton = styled(Button)`
  position: absolute;
  bottom: -36px;
  left: calc(50% - 48px);
  cursor: pointer;
`;

export const SliderLayout = styled("div")<ISliderProps>`
  position: fixed;
  width: 100%;
  min-height: 392px;
  top: 0;
  left: 0;
  transform: translateY(${({ isOpen }) => (isOpen ? "0" : "-100%")});
  transition: transform 0.3s ease-out;
  z-index: 10;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    min-height: 350px;
    height: calc(100vh - 36px);
  }
`;

export const Wrapper = styled("div")`
  height: 100%;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    overflow-y: scroll;
    padding-inline: 24px;
    padding-block: 40px;
  }
`;

export const LeftSlider = styled("div")`
  width: calc(50% - 32px);
  display: inline-block;
  margin-right: 32px;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 100%;
    margin-right: 0;
  }
`;

export const RightSlider = styled("div")`
  width: calc(50% - 32px);
  display: inline-block;
  margin-left: 32px;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 100%;
    margin-left: 0;
    margin-top: 32px;
  }
`;
export const SliderWrapper = styled("div")`
  position: relative;
  width: 100%;
  height: 100%;
  padding-inline: 64px;
  padding-block: 24px;
  display: flex;
  align-items: center;
  background: rgba(11, 16, 26, 0.8);
  backdrop-filter: blur(7.5px);

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: 0;
  }
`;
export const StyledSlider = styled(Slider)`
  width: 100%;
`;
