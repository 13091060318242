import React from "react";

interface IProps {
  className?: string;
}
export const Arrow = ({ className }: IProps) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12"
        cy="12"
        r="11.5"
        transform="matrix(-1 0 0 1 24.5 0)"
        stroke="white"
      />
      <path
        d="M10.5 7L15.1861 11.2601C15.6225 11.6568 15.6225 12.3432 15.1861 12.7399L10.5 17"
        stroke="white"
        strokeLinecap="round"
      />
    </svg>
  );
};
