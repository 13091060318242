import React from "react";

interface IProps {
  className?: string;
}
export const SlideIcon = ({ className }: IProps) => {
  return (
    <svg
      width="96"
      height="32"
      className={className}
      viewBox="0 0 96 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_378_11370)">
        <path
          d="M0 0H96L87.8933 21.618C85.5514 27.8628 79.5815 32 72.912 32H23.088C16.4185 32 10.4486 27.8628 8.10674 21.618L0 0Z"
          fill="#0D131D"
          fillOpacity="0.8"
        />
      </g>
      <path
        className="arrow"
        d="M47.2928 18.2929C47.6833 18.6834 48.3165 18.6834 48.707 18.2929L52.2928 14.7071C52.9228 14.0771 52.4766 13 51.5857 13H44.4141C43.5232 13 43.077 14.0771 43.707 14.7071L47.2928 18.2929Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_b_378_11370"
          x="-15"
          y="-15"
          width="126"
          height="62"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_378_11370"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_378_11370"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
