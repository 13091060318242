import { styled } from "@mui/material";
import { Colors } from "consts/colors";

export const PhoneOutline = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  justifyContent: "space-between",

  width: 320,
  height: 568,
  border: `8px solid ${Colors.GREY}`,
  borderRadius: 24,
  boxSizing: "border-box",

  paddingInline: 16,
  paddingBlock: "16px 40px",

  [theme.breakpoints.down("md")]: {
    width: 300,
    height: 521,
  },

  [theme.breakpoints.down("sm")]: {
    width: 288,
    height: 501,
  },
}));

export const IconItem = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  background-color: ${Colors.BACKGROUND};
  border-radius: 8px;
`;

export const IconsLayout = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 16px;
`;

export const NumLayout = styled("div")`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 40px);
  gap: 8px;

  margin-bottom: 32px;
`;
export const NumItem = styled("span")`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${Colors.BACKGROUND};

  border: 1px solid ${Colors.GREY};
  border-radius: 8px;

  color: white;
`;

export const NextButton = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 8px;
  height: 40px;
  background-color: ${Colors.PRIMARY};
  color: ${Colors.BACKGROUND};
`;

export const PhoneHead = styled("div")`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const TotalBlock = styled("span")`
  padding-inline: 8px;
  border-radius: 8px;
  height: 24px;
  border: 1px solid ${Colors.BACKGROUND_SECONDARY};
  color: ${Colors.BACKGROUND_SECONDARY};
`;
