import {styled} from "@mui/material";

export const Container = styled("div")(({ theme }) => ({
    maxWidth: 952,
    marginInline: "auto",
    [theme.breakpoints.down("md")]: {
        maxWidth: 624,
    },
    [theme.breakpoints.down("sm")]: {
        maxWidth: 288,
    },
}));