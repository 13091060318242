import React from "react";

export const BookIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M2.5 6.42737C4.00001 5.02421 11.0003 5.02448 12.5 6.42737C14 5.02448 21.0003 5.02421 22.5 6.42737V19.8629C20.7619 18.237 14.2381 18.237 12.5 19.8629C10.7619 18.237 4.23809 18.237 2.5 19.8629V6.42737Z"
        stroke="white"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <rect x="11.5" y="5.5" width="2" height="14" fill="white" />
    </svg>
  );
};
