import React from "react";

interface IProps {
  className?: string;
}
export const CornerBig = ({ className }: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 0C26 2.79124 25.5602 5.47975 24.746 8H33V10H24.0073C21.5134 15.9805 16.8499 20.8299 11 23.5653V32H9V24.4002C6.1956 25.4349 3.16379 26 0 26V24C3.1827 24 6.22071 23.3805 9 22.2554V10V8H11H22.6344C23.5188 5.49777 24 2.80508 24 0H26ZM11 10H21.8238C19.584 14.8803 15.7552 18.8798 11 21.3364V10Z"
        fill="currentColor"
      />
    </svg>
  );
};
