import { styled, Typography } from "@mui/material";
import { Colors } from "consts/colors";

export const StyledSection = styled("section")(({ theme }) => ({
  width: "100%",
  backgroundColor: Colors.BACKGROUND_SECONDARY,
  backgroundImage: `url("/background/hero.png")`,
  backgroundSize: "auto",
  backgroundRepeat: "no-repeat",
  [theme.breakpoints.up("xl")]: {
    backgroundSize: "cover",
  },
}));

export const Title = styled(Typography)`
  font-size: 40px;
  font-weight: 700;

  color: white;

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 24px;
  }
`;
export const Description = styled(Typography)`
  font-size: 24px;
  line-height: 33.6px;
  font-weight: 400;
  color: white;

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 18px;
    line-height: 25.2px;
  }
`;

export const Layout = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-end",
  padding: 0,
  gap: 82,

  [theme.breakpoints.down("md")]: {
    gap: 24,
  },

  [theme.breakpoints.down("sm")]: {
    gap: 40,
    flexDirection: "column-reverse",
    marginTop: 24,
  },
}));

export const StoreLink = styled("a")`
  line-height: 1;
`;
export const InfoBlock = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0;
  gap: 32px;

  max-width: 550px;

  ${({ theme }) => theme.breakpoints.down("md")} {
    max-width: 300px;
    gap: 16px;
  }
`;

export const LogosLayout = styled("div")(({ theme }) => ({
  display: "flex",
  gap: 64,
  paddingBlock: 104,

  [theme.breakpoints.down("md")]: {
    paddingBlock: "150px 112px",
  },

  [theme.breakpoints.down("sm")]: {
    paddingBlock: "40px 80px",
    flexDirection: "column",
    gap: 24,
  },
}));
