import React from "react";

import { gameStoreIcon } from "consts/storeTypes";

import { Wrapper, LinksLayout, Link } from "./styles";
import { IGameLinksProps } from "./interfaces";

export const GameLinks = ({ links }: IGameLinksProps) => {
  return (
    <Wrapper>
      <LinksLayout>
        {links.map(({ url, type }, index) => (
          <Link key={index} href={url} target="_blank">
            {gameStoreIcon[type]}
          </Link>
        ))}
      </LinksLayout>
    </Wrapper>
  );
};
