import React from "react";
export const RuStore = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.81178 37.1882C5.62355 40 10.149 40 19.2 40H20.8C29.851 40 34.3765 40 37.1882 37.1882C40 34.3765 40 29.8509 40 20.7999V20.7999V19.1999V19.1999C40 10.1489 40 5.62336 37.1882 2.81161C34.3765 0 29.851 0 20.8 0H19.2C10.149 0 5.62355 0 2.81177 2.81162C0 5.62341 0 10.1489 0 19.1999V20.7999C0 29.8509 0 34.3764 2.81178 37.1882ZM16.413 10.4137L21.2971 11.6338V9.28545C21.2971 8.02959 22.4838 7.1085 23.7079 7.41432L31.4597 9.35077C32.5374 9.61995 33.2931 10.584 33.2931 11.6897V24.716C33.2931 25.9719 32.1064 26.893 30.8823 26.5871L28.1814 25.9125C27.8551 25.8196 27.6245 25.5264 27.6129 25.1854L27.2756 15.238C27.1781 13.9214 26.2008 12.8742 25.1794 12.5658C25.1221 12.5485 25.0609 12.5719 25.0269 12.6212C24.9923 12.6713 25.0057 12.7407 25.0539 12.7779C25.071 12.7913 25.0903 12.8066 25.1112 12.824C25.5326 13.168 25.8304 13.648 25.9455 14.1882C25.9801 14.3486 26.0002 14.5222 26.0002 14.7093L25.9981 27.7154C25.9981 28.9712 24.8115 29.8923 23.5873 29.5865L20.8437 28.9012C20.539 28.7949 20.3273 28.5113 20.3162 28.1842L19.979 18.236C19.8814 16.9194 18.904 15.8723 17.8827 15.5639C17.8254 15.5466 17.7641 15.5699 17.7302 15.6192C17.6957 15.6694 17.709 15.7388 17.7571 15.776C17.7736 15.7888 17.7919 15.8033 17.8117 15.8198C18.3343 16.2445 18.6679 16.8779 18.7002 17.5765C18.7024 17.6193 18.7035 17.663 18.7035 17.7073L18.7028 28.3618L19.9772 28.6847L18.7028 28.3664V30.7145C18.7028 31.9704 17.516 32.8914 16.2919 32.5857L8.54013 30.6492C7.4624 30.38 6.70676 29.416 6.70676 28.3103V15.284C6.70676 14.0281 7.89345 13.107 9.11758 13.4129L14.0021 14.633V12.2849C14.0021 11.029 15.1889 10.1079 16.413 10.4137Z"
        fill="currentColor"
      />
    </svg>
  );
};
