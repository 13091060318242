import React from "react";
import {
  Layout,
  RightLine,
  LeftLine,
  TopLine,
  BottomLine,
  TopLeft,
  TopRight,
  BottomLeft,
  BottomRight,
  Text,
} from "./styles";

export const Description = () => {
  return (
    <Layout>
      <LeftLine />
      <RightLine />
      <TopLine />
      <BottomLine />
      <TopLeft />
      <TopRight />
      <BottomLeft />
      <BottomRight />
      <Text>
        Необдуманные манипуляции ученых-волшебников Вириона и Киры привели к
        активации древнего артефакта. И теперь, чтобы избежать непредсказуемых
        последствий неизведанной силы, друзья вынуждены отправиться в полное
        опасных испытаний путешествие. Но им неведомо, что они могут оказаться
        инструментом могущественных сил, которые преследуют собственные цели.
        Только бескорыстие, искренность и преданность друг другу помогут
        участникам путешествия выполнить поставленные перед ними задачи.
      </Text>
    </Layout>
  );
};
