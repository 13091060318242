import { styled } from "@mui/material";
import { Corner } from "./Corner";

export const IconLayout = styled("span")`
  position: absolute;
  width: 24px;
  height: 24px;
  top: -12px;
  left: calc(50% - 12px);
`;
export const StyledCorner = styled(Corner)`
  position: absolute;
  width: 21px;
  height: 21px;
`;

export const HorizontalLine = styled("div")`
  width: calc(50% - 57px);
  height: 1px;
  background-color: #fff;
  position: absolute;
`;

export const HorizontalLineLeft = styled(HorizontalLine)`
  top: 0;
  left: 21px;
`;
export const HorizontalLineRight = styled(HorizontalLine)`
  top: 0;
  right: 21px;
`;

export const TopLeft = styled(StyledCorner)`
  top: 0;
  left: 0;
`;
export const BottomLeft = styled(StyledCorner)`
  transform: scaleY(-1);
  bottom: 0;
  left: 0;
`;

export const TopRight = styled(StyledCorner)`
  transform: scaleX(-1);
  top: 0;
  right: 0;
`;

export const BottomRight = styled(StyledCorner)`
  transform: scale(-1, -1);
  bottom: 0;
  right: 0;
`;
