import React, { ReactNode } from "react";
import { Layout } from "./styles";

interface IProps {
  children: ReactNode;
  className?: string;
}
export const CarouselItem = ({ className, children }: IProps) => {
  return <Layout className={className}>{children}</Layout>;
};
