import { styled } from "@mui/material";

export const Layout = styled("div")`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(13, 19, 29, 0.2);
  border-radius: 16px;
  min-height: 304px;
  width: 100%;
  overflow: hidden;
`;
