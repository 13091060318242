import React from "react";

import {
  Button,
  StyledBackgroundDesktop,
  StyledBackgroundMobile,
  LinkTitle,
} from "./styles";
import {ELIXIR_CONFIG} from "config";
export const Link = () => {
  const handleClick = () => {
    window.open(ELIXIR_CONFIG.bookUrl, "_blank");
  };
  return (
    <Button onClick={handleClick}>
      <StyledBackgroundDesktop />
      <StyledBackgroundMobile />
      <LinkTitle>Купить на Литрес</LinkTitle>
    </Button>
  );
};
