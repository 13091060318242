import { RefObject, useCallback, useEffect } from "react";

interface IUseBackdropClick {
  ref: RefObject<HTMLDivElement>;
  callback: () => void;
}
export function useBackdropClick({ ref, callback }: IUseBackdropClick) {
  const handle = useCallback(callback, []);

  useEffect(() => {
    if (!ref?.current) {
      return;
    }

    const clickHandler = (event: MouseEvent) => {
      // @ts-ignore
      if (!ref.current?.contains(event.target)) {
        handle();
      }
    };

    document.addEventListener("click", clickHandler);
    return () => {
      document.removeEventListener("click", clickHandler);
    };
  }, [ref]);
}
