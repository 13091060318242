import { styled, css } from "@mui/material";
import { CornerBig } from "../CornerBig";

export const StyledCorner = styled(CornerBig)`
  position: absolute;
  width: 32px;
  height: 32px;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 24px;
    height: 24px;
  }
`;

type TPosition = "normal" | "shifted";

function getMobilePositionValue(position?: TPosition) {
  if (!position) {
    return "auto";
  }

  switch (position) {
    case "normal":
      return "8px";
    case "shifted":
      return "32px";
  }
}

function getPositionValue(position?: TPosition) {
  if (!position) {
    return "auto";
  }

  switch (position) {
    case "normal":
      return "24px";
    case "shifted":
      return "57px";
  }
}
interface ILineProps {
  top: TPosition;
  bottom: TPosition;
  left: TPosition;
  right: TPosition;

  bgColor: "primary" | "secondary";
}

const getLineStyles =
  (isMobile?: boolean) =>
  ({ bgColor = "primary", top, left, right, bottom }: Partial<ILineProps>) => {
    const color = bgColor === "primary" ? "#444c5e" : "#f5f2ec";

    return css`
      background-color: ${color};
      top: ${isMobile ? getMobilePositionValue(top) : getPositionValue(top)};
      left: ${isMobile ? getMobilePositionValue(left) : getPositionValue(left)};
      right: ${isMobile
        ? getMobilePositionValue(right)
        : getPositionValue(right)};
      bottom: ${isMobile
        ? getMobilePositionValue(bottom)
        : getPositionValue(bottom)};
    `;
  };

export const HorizontalLine = styled("div")<Partial<ILineProps>>`
  position: absolute;
  width: 80px;
  height: 2px;
  opacity: 0.5;
  ${getLineStyles()}

  ${({ theme }) => theme.breakpoints.down("sm")} {
    ${getLineStyles(true)}
  }
`;
export const VerticalLine = styled("div")<Partial<ILineProps>>`
  position: absolute;
  height: 80px;
  width: 2px;
  opacity: 0.5;
  ${getLineStyles()}

  ${({ theme }) => theme.breakpoints.down("sm")} {
    ${getLineStyles(true)}
  }
`;

export const TopLeft = styled(StyledCorner)`
  top: 24px;
  left: 24px;
  color: #444c5e;
  opacity: 0.5;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    top: 8px;
    left: 8px;
  }
`;
export const BottomLeft = styled(StyledCorner)`
  transform: scaleY(-1);
  bottom: 24px;
  left: 24px;
  color: #f5f2ec;
  opacity: 0.5;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    bottom: 8px;
    left: 8px;
  }
`;

export const TopRight = styled(StyledCorner)`
  transform: scaleX(-1);
  top: 24px;
  right: 24px;
  color: #444c5e;
  opacity: 0.5;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    top: 8px;
    right: 8px;
  }
`;

export const BottomRight = styled(StyledCorner)`
  transform: scale(-1, -1);
  bottom: 24px;
  right: 24px;
  color: #f5f2ec;
  opacity: 0.5;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    bottom: 8px;
    right: 8px;
  }
`;
