import { styled, css } from "@mui/material";

export const fontStyles = css`
  margin: 0;
  color: #0d131d;
  font-family: "Furore", sans-serif;
  text-shadow: 0 7.1102705001831055px 44.43918991088867px
    rgba(250, 249, 241, 0.5);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const Author = styled("h2")`
  ${fontStyles}
  font-size: 36.262px;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 20px;
  }
`;

const flexStyles = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
`;

export const HeroLayout = styled("div")`
  ${flexStyles}
  height: 100vh;
  padding-bottom: 72px;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding-bottom: 32px;
  }
`;
export const Title = styled("h1")`
  ${fontStyles}
  font-size: 116.04px;

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 64px;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 48px;
  }
`;

export const Layout = styled("section")`
  padding-inline: 24px;
  ${flexStyles}
  position: relative;

  background-image: url("/background/book-desktop.png");
  background-position: center center;
  background-size: 100% 100%;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down("md")} {
    background-image: url("/background/book-tablet.png");
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    background-image: url("/background/book-mobile.png");
  }

  ${({ theme }) => theme.breakpoints.down("xs")} {
    background-image: url("/background/book-mobile-small.png");
  }
`;
