import React from "react";
export const GoogleStore = () => {
  return (
    <svg
      width="38"
      height="40"
      viewBox="0 0 38 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.402344 2.4547V37.5435C0.402579 37.6196 0.425284 37.694 0.467613 37.7573C0.509942 37.8206 0.57001 37.8699 0.640285 37.8992C0.71056 37.9285 0.787913 37.9363 0.862645 37.9218C0.937377 37.9073 1.00616 37.8711 1.06037 37.8176L19.3307 20L1.06037 2.18059C1.00616 2.12715 0.937377 2.09089 0.862645 2.07638C0.787913 2.06187 0.71056 2.06974 0.640285 2.09901C0.57001 2.12829 0.509942 2.17766 0.467613 2.24093C0.425284 2.30421 0.402579 2.37857 0.402344 2.4547Z"
        fill="currentColor"
      />
      <path
        d="M26.9913 12.6787L4.08265 0.0574083L4.06837 0.0493727C3.67373 -0.16491 3.29873 0.369011 3.62194 0.679722L21.5797 17.8509L26.9913 12.6787Z"
        fill="currentColor"
      />
      <path
        d="M3.62373 39.3203C3.29873 39.631 3.67373 40.1649 4.07015 39.9506L4.08444 39.9426L26.9913 27.3213L21.5797 22.1473L3.62373 39.3203Z"
        fill="currentColor"
      />
      <path
        d="M36.2394 17.7679L29.8421 14.2447L23.827 20L29.8421 25.7526L36.2394 22.2321C37.9795 21.2705 37.9795 18.7295 36.2394 17.7679Z"
        fill="currentColor"
      />
    </svg>
  );
};
