import React from "react";
interface IProps {
  className?: string;
}
export const BackgroundMobile = ({ className }: IProps) => {
  return (
    <svg
      width="326"
      height="48"
      viewBox="0 0 326 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        width="14.5147"
        height="2"
        transform="matrix(-1 0 0 1 21 23)"
        fill="currentColor"
      />
      <rect
        width="6"
        height="6"
        transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 8.48535 24)"
        fill="currentColor"
      />
      <path
        d="M47.6585 0H278.342L307 24L278.342 48H47.6585L19 24L47.6585 0Z"
        fill="#0D131D"
        fillOpacity="0.9"
      />
      <path
        d="M20.5575 24L48.0219 1H277.978L305.442 24L277.978 47H48.0219L20.5575 24Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <mask
        id="mask0_378_10918"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="21"
        y="2"
        width="282"
        height="44"
      >
        <path
          d="M47.3816 2H276.618L303 24L276.618 46H47.3816L21 24L47.3816 2Z"
          fill="#0D131D"
        />
      </mask>
      <g mask="url(#mask0_378_10918)">
        <rect
          width="56"
          height="46"
          transform="translate(246)"
          fill="#040912"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M268.055 16.3734L262 12L263.385 11L280 23L270.368 29.9566C270.37 29.9597 270.372 29.9628 270.373 29.9659L268.901 31.031C268.899 31.0276 268.896 31.0242 268.894 31.0208L263.385 35L262 34L268.056 29.6264C265.783 25.3713 265.783 20.6286 268.055 16.3734ZM269.583 28.5232C267.885 24.9503 267.885 21.0496 269.583 17.4767L277.231 23L269.583 28.5232Z"
          fill="white"
        />
      </g>
      <rect
        x="305"
        y="23"
        width="14.5147"
        height="2"
        fill="currentColor"
      />
      <rect
        x="317.515"
        y="24"
        width="6"
        height="6"
        transform="rotate(-45 317.515 24)"
        fill="currentColor"
      />
    </svg>
  );
};
