import React from "react";
import Helmet from "react-helmet";
import { IProps } from "./interfaces";

import "./style.css";

export const Layout: React.FC<IProps> = ({ children, title, meta = [] }) => (
  <>
    <Helmet>
      <title>{title}</title>
      {meta.map(({ name, content }) => (
        <meta key={name} name={name} content={content} />
      ))}
      <link rel="icon" type="image/x-icon" href="/favicon.png" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="use-credentials"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Open+Sans&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    {children}
  </>
);
