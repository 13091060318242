import React, { ReactNode } from "react";
import { HashContext } from "./HashContext";

interface IHashProviderProps {
  hash?: string;
  children?: ReactNode;
}

export const HashProvider: React.FC<IHashProviderProps> = ({
  hash,
  children,
}) => {
  return <HashContext.Provider value={hash}>{children}</HashContext.Provider>;
};
