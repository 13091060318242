import { styled } from "@mui/material";

export const LinksLayout = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`;

export const Link = styled("a")`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 41px;
  height: 41px;
  opacity: 0.7;
  color: white;

  :hover {
    opacity: 1;
  }
`;

export const Wrapper = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: #0d131d66;
  transition: opacity 0.2s ease-in;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;
