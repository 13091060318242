import { css, styled } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Arrow } from "./components/Arrow";
import { CarouselItem } from "./components/CarouselItem";
import { Button } from "../Button";

interface VariableProps {
  isVariable?: boolean;
}

const variableStyles = css`
  padding-inline: 16px;
`;

export const Item = styled(CarouselItem)<VariableProps>`
  ${({ isVariable }) => isVariable && variableStyles}
`;

export const ItemLayout = styled("div")<VariableProps>`
  padding-inline: 32px;
  padding-block: 20px;

  ${({ isVariable }) => isVariable && variableStyles}

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding-inline: 24px;
  }
`;
export const StyledCarousel = styled(Slider)`
  width: 100%;
`;
export const LeftArrow = styled(Arrow)`
  transform: scaleX(-1);
`;
export const RightArrow = styled(Arrow)``;

export const ArrowButton = styled(Button)`
  width: 24px;
  height: 24px;
  position: absolute;
  z-index: 10;
  top: calc(50% - 12px);
  &::before {
    display: none;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    &:first-of-type {
      left: -12px;
    }

    &:last-of-type {
      right: -12px;
    }
  }
`;

export const Layout = styled("div")`
  position: relative;

  ::before {
    content: "";
    width: calc(100% - 42px);
    position: absolute;
    bottom: 0;
    left: 21px;
    height: 1px;
    background: white;
  }
`;
