const CONFIG = {
    // поле description для SEO
    seoDescription: "Поддавшись опасному любопытству, ученые-волшебники Вирион и Кира случайно активировали древний артефакт и теперь обязаны найти еще двух товарищей и вместе с ними пройти неведомые и опасные испытания. В случае успеха они обретут возможность изменить к лучшему жизнь всего человечества, но шансы на благополучный исход невелики. Против четырех друзей, вынужденно ставших героями, играют не только их собственные страхи, но и могущественные таинственные существа, которые преследуют собственные цели…",
    // поле robots для SEO
    seoRobots: "",
    // поле keyword для SEO
    seoKeywords: "",
    // ссылка на книгу
    bookUrl: "https://www.litres.ru/book/bulat-gayneev/eliksir-68833206/",
};

export default CONFIG;