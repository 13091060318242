import { styled } from "@mui/material";
import { Colors } from "consts/colors";
import { Link } from "gatsby-plugin-react-i18next";

export const HeadContainer = styled("header")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  height: 112,
  [theme.breakpoints.down("sm")]: {
    height: 80,
  },
}));

interface IProps {
  side: "left" | "right";
}
export const LanguageLayout = styled("div")<IProps>`
  background-color: ${Colors.BACKGROUND};

  display: flex;
  align-items: center;
  height: 32px;
  width: 127px;

  border-radius: 96px;

  position: relative;

  &::after {
    position: absolute;
    content: "";
    width: 63px;
    height: 32px;
    border-radius: 96px;
    background-color: ${Colors.GREY};

    will-change: transform;
    transform: translateX(${({ side }) => (side === "left" ? "0" : "100%")});
    transition: transform 0.2s linear;

    z-index: 1;
  }
`;

export const ItemsLayout = styled("div")`
  display: flex;
  position: absolute;
  background-color: transparent;
  height: 32px;
  border-radius: 96px;
  width: 100%;
  top: 0;
  left: 0;

  z-index: 2;
`;

export const Item = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  cursor: pointer;
  text-decoration: none;
`;
