import React from "react";
interface IProps {
  className?: string;
}
export const Background = ({ className }: IProps) => {
  return (
    <svg
      width="586"
      height="64"
      viewBox="0 0 586 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        width="80.5147"
        height="2"
        transform="matrix(-1 0 0 1 87 31)"
        fill="currentColor"
      />
      <rect
        width="6"
        height="6"
        transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 8.48535 32)"
        fill="currentColor"
      />
      <path
        d="M113.658 0H472.342L501 32L472.342 64H113.658L85 32L113.658 0Z"
        fill="#0D131D"
        fillOpacity="0.9"
      />
      <path
        d="M86.3424 32L114.105 1H471.895L499.658 32L471.895 63H114.105L86.3424 32Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <mask
        id="mask0_378_10672"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="87"
        y="2"
        width="412"
        height="60"
      >
        <path
          d="M113.382 2H472.618L499 32L472.618 62H113.382L87 32L113.382 2Z"
          fill="#0D131D"
        />
      </mask>
      <g mask="url(#mask0_378_10672)">
        <rect
          width="72"
          height="64"
          transform="translate(427)"
          fill="#040912"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M457.055 25.3734L451 21L452.385 20L469 32L459.368 38.9566C459.37 38.9597 459.372 38.9628 459.373 38.9659L457.901 40.031C457.899 40.0276 457.896 40.0242 457.894 40.0208L452.385 44L451 43L457.056 38.6264C454.783 34.3713 454.783 29.6286 457.055 25.3734ZM458.583 37.5232C456.885 33.9503 456.885 30.0496 458.583 26.4767L466.231 32L458.583 37.5232Z"
          fill="white"
        />
      </g>
      <rect
        x="499"
        y="31"
        width="80.5147"
        height="2"
        fill="currentColor"
      />
      <rect
        x="577.515"
        y="32"
        width="6"
        height="6"
        transform="rotate(-45 577.515 32)"
        fill="currentColor"
      />
    </svg>
  );
};
