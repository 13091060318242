import React from "react";

import { Author, Layout, Title, HeroLayout } from "./styles";
import { Link } from "./components/Link";
import { Description } from "./components/Description";
import { Background } from "./components/Background";
import { ProductSlider } from "components";

export const MainPageContainer = () => {
  return (
    <Layout>
      <ProductSlider />
      <Background />
      <HeroLayout>
        <Author>Булат Гайнеев</Author>
        <Title>ЭЛИКСИР</Title>
        <Link />
      </HeroLayout>
      <Description />
    </Layout>
  );
};
