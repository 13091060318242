import {
  Icon,
  SlideButton,
  SliderLayout,
  SliderWrapper,
  StyledSlider,
  LeftSlider,
  RightSlider,
  Wrapper,
} from "./styles";
import React, { useEffect, useRef, useState } from "react";
import { ProductItem } from "./components/ProductItem";
import { GameLinks } from "./components/GameLinks";
import { ALL_CONFIG } from "config";
import { useBackdropClick } from "./useBackdropClick";
import { useHashForSlider } from "./useHashForSlider";

export const ProductSlider = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { game, all, book } = useHashForSlider();

  const handleClick = () => {
    setIsOpen((prevValue) => !prevValue);
  };

  useBackdropClick({
    ref,
    callback: () => {
      setIsOpen(false);
    },
  });

  useEffect(() => {
    if (game || all || book) {
      setIsOpen(true);
    }
  }, [game, all, book]);

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : '';

    return () => {
      document.body.style.overflow = isOpen ? 'hidden' : '';
    };
  }, [isOpen]);

  return (
    <SliderLayout ref={ref} isOpen={isOpen}>
      <SliderWrapper>
        <Wrapper>
          <LeftSlider>
            <StyledSlider type="book" slideNum={book}>
              {ALL_CONFIG.books.map((item, index) => (
                <ProductItem key={`book_${index}`} {...item} />
              ))}
            </StyledSlider>
          </LeftSlider>
          <RightSlider>
            <StyledSlider type="game" slideNum={game}>
              {ALL_CONFIG.games.map(({ storeLinks, ...item }, index) => (
                <>
                  <ProductItem key={`book_${index}`} {...item} />
                  {storeLinks && <GameLinks links={storeLinks} />}
                </>
              ))}
            </StyledSlider>
          </RightSlider>
        </Wrapper>
        <SlideButton onClick={handleClick}>
          <Icon isOpen={isOpen} />
        </SlideButton>
      </SliderWrapper>
    </SliderLayout>
  );
};
