import { styled, css, Typography } from "@mui/material";
import { Colors } from "consts/colors";

interface ILayoutProps {
  isCopied: boolean;
}

const getLayoutStyles = (isCopied: boolean) => {
  if (isCopied) {
    return css`
      border: 1px dashed ${Colors.PRIMARY};
      color: ${Colors.PRIMARY};
    `;
  }

  return css`
    border: 1px solid ${Colors.BACKGROUND};
    color: ${Colors.GREY};
    &:hover {
      border-style: dashed;
      border-color: ${Colors.VIOLET};
      color: ${Colors.VIOLET};
    }
  `;
};
export const Layout = styled("button")<ILayoutProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;

  cursor: pointer;
  border-radius: 24px;
  height: 88px;

  background-color: ${Colors.BACKGROUND};

  ${({ isCopied }) => getLayoutStyles(isCopied)}

  ${({ theme }) => theme.breakpoints.down("sm")} {
    height: 56px;
    border-radius: 16px;
    gap: 18px;
  }
`;

export const EmailInfo = styled(Typography)`
  font-size: 32px;
  line-height: 24px;
  
  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 20px;
    line-height: 24px;
  }
`;
