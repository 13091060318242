import React from "react";

interface IProps {
  className?: string;
}
export const Corner = ({ className }: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 23 23"
      fill="none"
      className={className}
    >
      <path
        d="M1 23L1 17C0.999999 8.16345 8.16344 1 17 1L23 1"
        stroke="white"
      />
    </svg>
  );
};
