import React from "react";
import { BookIcon } from "./BookIcon";
import { GameIcon } from "./GameIcon";
import {
  BottomRight,
  BottomLeft,
  TopRight,
  TopLeft,
  HorizontalLineLeft,
  HorizontalLineRight,
  IconLayout,
} from "./styles";

interface IBackgroundProps {
  type: "book" | "game";
}
export const Background = ({ type }: IBackgroundProps) => {
  return (
    <>
      <IconLayout>{type === "game" ? <GameIcon /> : <BookIcon />}</IconLayout>
      <HorizontalLineLeft />
      <HorizontalLineRight />
      <TopLeft />
      <TopRight />
      <BottomLeft />
      <BottomRight />
    </>
  );
};
