import * as React from "react";
import { Layout, HashProvider } from "components";
import { MainPageContainer } from "containers";
import { ELIXIR_CONFIG } from "config";
import { PageProps } from "gatsby";

const IndexPage = ({ location }: PageProps) => {
  const meta = [
    {
      name: "description",
      content: ELIXIR_CONFIG.seoDescription,
    },
    {
      name: "robots",
      content: ELIXIR_CONFIG.seoRobots,
    },
    {
      name: "keywords",
      content: ELIXIR_CONFIG.seoKeywords,
    },
  ];
  return (
    <HashProvider hash={location.hash}>
      <Layout title="Эликсир" meta={meta}>
        <MainPageContainer />
      </Layout>
    </HashProvider>
  );
};

export default IndexPage;
