import { styled } from "@mui/material";
import { Colors } from "consts/colors";

export const StyledSection = styled("section")`
  background-color: ${Colors.BACKGROUND_PRIMARY};
`;

export const Layout = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 24,
  paddingBlock: 88,

  [theme.breakpoints.down("xs")]: {
    paddingBlock: 64,
  },
}));
