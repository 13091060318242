import {
  BottomLeft,
  BottomRight,
  TopLeft,
  TopRight,
  HorizontalLine,
  VerticalLine,
} from "./styles";
import React from "react";

export const Background = () => {
  return (
    <>
      <VerticalLine top="shifted" left="normal" />
      <VerticalLine top="shifted" right="normal" />
      <VerticalLine bgColor="secondary" bottom="shifted" left="normal" />
      <VerticalLine bgColor="secondary" bottom="shifted" right="normal" />
      <HorizontalLine top="normal" left="shifted" />
      <HorizontalLine top="normal" right="shifted" />
      <HorizontalLine bgColor="secondary" bottom="normal" left="shifted" />
      <HorizontalLine bgColor="secondary" bottom="normal" right="shifted" />
      <TopLeft />
      <TopRight />
      <BottomLeft />
      <BottomRight />
    </>
  );
};
