import React from "react";

interface IProps {
  className?: string;
}
export const Corner = ({ className }: IProps) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 0C14 1.38995 13.7974 2.73258 13.4202 4H16.5V6H12.6527C11.3498 8.74284 9.18117 10.9948 6.5 12.4028V16H4.5V13.2611C3.08771 13.7402 1.5742 14 0 14V12C1.59135 12 3.11035 11.6902 4.5 11.1277V6V4H6.5H11.3172C11.7594 2.74888 12 1.40254 12 0H14ZM6.5 6H10.3946C9.43873 7.65244 8.10012 9.05579 6.5 10.0888V6Z"
        fill="currentColor"
      />
    </svg>
  );
};
