import { styled } from "@mui/material";
export const Wrapper = styled("div")`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

export const ImageLayout = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 180px;
`;

export const Image = styled("img")`
  width: auto;
  border-radius: 10px;
`;

export const Title = styled("p")`
  margin: 0;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 110%; /* 17.6px */
`;

export const Logo = styled("img")`
  width: 32px;
  height: 32px;
  border-radius: 4px;
`;

export const Layout = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 16px;
`;
