import React from "react";
export const YndxStore = () => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.5631 32.2254V38H17.479V28.263L6 4H12.3477L21.2921 22.9812C23.0158 26.6078 23.5631 27.8691 23.5631 32.2254ZM35 4L27.5379 20.4393H21.3541L28.8163 4H35Z"
        fill="currentColor"
      />
    </svg>
  );
};
