import { css, styled } from "@mui/material";
import { fontStyles } from "../../styles";
import { Background } from "./Background";
import { BackgroundMobile } from "./BackgroundMobile";
import { Button as BaseButton } from "components";

export const LinkTitle = styled("p")`
  ${fontStyles}
  font-size: 24px;
  line-height: 100%;
  display: block;
  margin-right: 30px;

  text-transform: uppercase;

  color: rgba(255, 255, 255, 1);

  position: absolute;
  top: 18px;
  left: auto;
  right: auto;
  text-shadow: inherit;

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 16px;
    top: 14px;
  }
`;

export const StyledBackgroundDesktop = styled(Background)`
  ${({ theme }) => theme.breakpoints.down("md")} {
    display: none;
  }
`;

export const StyledBackgroundMobile = styled(BackgroundMobile)`
  ${({ theme }) => theme.breakpoints.up("md")} {
    display: none;
  }
`;
export const Button = styled(BaseButton)`
  display: flex;
  justify-content: center;

  margin-top: 30px;

  cursor: pointer;
  position: relative;
  color: rgba(255, 255, 255, 0.5);

  &:hover {
    color: rgba(255, 255, 255, 1);
    text-shadow: 0 0 20px #6d879f;
  }
`;
