import { styled, css } from "@mui/material";
import { Corner } from "../Corner";

export const StyledCorner = styled(Corner)`
  position: absolute;
  width: 24px;
  height: 24px;
  color: rgba(255, 255, 255, 0.5);
`;
export const TopLeft = styled(StyledCorner)`
  top: 8px;
  left: 8px;
`;
export const BottomLeft = styled(StyledCorner)`
  transform: scaleY(-1);
  bottom: 8px;
  left: 8px;
`;

export const TopRight = styled(StyledCorner)`
  transform: scaleX(-1);
  top: 8px;
  right: 8px;
`;

export const BottomRight = styled(StyledCorner)`
  transform: scale(-1, -1);
  bottom: 8px;
  right: 8px;
`;

export const Text = styled("p")`
  margin: 0;
  color: #fff;
  text-align: justify;
  font-family: Open Sans, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 14px;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 12px;
  }
`;

export const Layout = styled("div")`
  margin-bottom: 130px;
  padding: 32px;
  border-radius: 8px;
  background-color: rgba(13, 19, 29, 0.8);
  position: relative;
  max-width: 586px;

  ${({ theme }) => theme.breakpoints.down("md")} {
    max-width: 326px;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    max-width: 272px;
    margin-bottom: 24px;
  }
`;

const HorizontalLine = styled("div")`
  height: 2px;
  width: calc(100% - 64px);
  background-color: rgba(255, 255, 255, 0.5);
`;

const VerticalLine = styled("div")`
  width: 2px;
  height: calc(100% - 64px);
  background-color: rgba(255, 255, 255, 0.5);
`;

export const LeftLine = styled(VerticalLine)`
  position: absolute;
  top: 32px;
  left: 8px;
`;

export const RightLine = styled(VerticalLine)`
  position: absolute;
  top: 32px;
  right: 8px;
`;

export const TopLine = styled(HorizontalLine)`
  position: absolute;
  top: 8px;
  left: 32px;
`;

export const BottomLine = styled(HorizontalLine)`
  position: absolute;
  bottom: 8px;
  left: 32px;
`;
