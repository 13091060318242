import React from "react";
import { IProps } from "./interfaces";

import { Wrapper, Title, Image, ImageLayout, Layout, Logo } from "./styles";
const MIDDLE_BUTTON = 1;
export const ProductItem = ({ image, name, link, logo }: IProps) => {
  const clickHandler = (event: React.MouseEvent) => {
    if (!link) {
      return;
    }
    if (link.startsWith("http") || event.button === MIDDLE_BUTTON) {
      window.open(link, "_blank");
    } else {
      window.location.href = link;
    }
  };
  return (
    <Wrapper onClick={link ? clickHandler : undefined}>
      <ImageLayout>
        <Image src={image} alt={name} />
      </ImageLayout>
      <Layout>
        {logo && <Logo alt="" src={logo} />}
        <Title>{name}</Title>
      </Layout>
    </Wrapper>
  );
};
