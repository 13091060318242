import React, { ReactElement } from "react";

import { RuStore, GoogleStore, AppStore, YndxStore } from "icons";

export enum EStoreTypes {
  APP_STORE = "appStore",
  GOOGLE_STORE = "googleStore",
  YNDX_STORE = "yndxStore",
  RU_STORE = "ruStore",
}

export const gameStoreIcon: Record<EStoreTypes, ReactElement> = {
  [EStoreTypes.APP_STORE]: <AppStore />,
  [EStoreTypes.GOOGLE_STORE]: <GoogleStore />,
  [EStoreTypes.RU_STORE]: <RuStore />,
  [EStoreTypes.YNDX_STORE]: <YndxStore />,
};
