import { ALL_CONFIG } from "config";
import { useHashContext } from "../HashProvider/HashContext";

interface ISliderIndexes {
  all?: boolean;
  book?: number;
  game?: number;
}

export function useHashForSlider(): ISliderIndexes {
  const hashParams = useHashContext();

  if (!hashParams) {
    return {};
  }

  if (hashParams === "all") {
    return {
      all: true,
    };
  }

  return {
    book: ALL_CONFIG.books.findIndex(({ id }) => id === hashParams),
    game: ALL_CONFIG.games.findIndex(({ id }) => id === hashParams),
  };
}
