import React, { useEffect, useRef } from "react";
import ReactSlider from "react-slick";

import { Background } from "./components/Background";

import {
  LeftArrow,
  RightArrow,
  ArrowButton,
  Layout,
  StyledCarousel,
  ItemLayout,
  Item,
} from "./styles";
import { IProps, IArrowProps } from "./interfaces";
import { useMediaQuery, useTheme } from "@mui/material";

const PrevButton = ({ className, onClick, style }: IArrowProps) => (
  <ArrowButton onClick={onClick} className={className} style={style}>
    <LeftArrow />
  </ArrowButton>
);

const NextButton = ({ className, onClick, style }: IArrowProps) => (
  <ArrowButton onClick={onClick} className={className} style={style}>
    <RightArrow />
  </ArrowButton>
);
export const Slider = ({ className, children, type, slideNum }: IProps) => {
  const theme = useTheme();
  const sliderRef = useRef<ReactSlider>(null);
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const isVariable = !isTablet && children.length > 2;

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: isTablet || children.length < 2 ? 1 : 2,
    slidesToScroll: 1,
    nextArrow: <NextButton />,
    prevArrow: <PrevButton />,
    variableWidth: isVariable,
  };

  useEffect(() => {
    if (slideNum) {
      sliderRef.current?.slickGoTo(slideNum);
    }
  }, [slideNum]);

  return (
    <Layout className={className}>
      <Background type={type} />
      <StyledCarousel ref={sliderRef} {...settings}>
        {children.map((item, index) => (
          <ItemLayout isVariable={isVariable} key={index}>
            <Item isVariable={isVariable}>{item}</Item>
          </ItemLayout>
        ))}
      </StyledCarousel>
    </Layout>
  );
};
