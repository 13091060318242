import React from "react";
export const AppStore = () => {
  return (
    <svg
      width="31"
      height="38"
      viewBox="0 0 31 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.4591 20.2083C25.4958 17.2748 27.0245 14.4992 29.4495 12.9627C27.9197 10.7111 25.3572 9.28344 22.6912 9.19748C19.8477 8.8899 17.091 10.951 15.642 10.951C14.165 10.951 11.934 9.22802 9.53162 9.27895C6.40022 9.38321 3.48098 11.2179 1.95749 14.0392C-1.31742 19.8824 1.12537 28.4698 4.26245 33.193C5.832 35.5058 7.66635 38.0892 10.0666 37.9976C12.4153 37.8972 13.2925 36.4542 16.1277 36.4542C18.9365 36.4542 19.7595 37.9976 22.2084 37.9394C24.7287 37.8972 26.3167 35.6163 27.8312 33.2816C28.9589 31.6337 29.8267 29.8123 30.4023 27.885C27.4405 26.5941 25.4626 23.5224 25.4591 20.2083Z"
        fill="currentColor"
      />
      <path
        d="M20.8335 6.0913C22.2077 4.39126 22.8847 2.20615 22.7207 0C20.6213 0.227241 18.682 1.26128 17.2892 2.8961C15.9273 4.49339 15.2186 6.64006 15.3526 8.76188C17.4528 8.78417 19.5174 7.77815 20.8335 6.0913Z"
        fill="currentColor"
      />
    </svg>
  );
};
